import {
  Card,
  CardBody,
  Col,
  Container,
  Button,
  Row,
  Input,
  Nav,
  NavItem,
  NavLink,
  Modal,
  Spinner,
  ModalHeader,
  ModalBody,
  Table,
  DropdownItem,
} from "reactstrap";
//Import Icons

import Chips from "react-chips";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import FeatherIcon from "feather-icons-react";
import { toast, ToastContainer } from "react-toastify";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import logoPurple from "../../assets/images/logopurple.png";
import { Elements } from "@stripe/react-stripe-js";
import classnames from "classnames";
import axios from "axios";
import { get_cookie } from "../../helpers/get_cookie";

export const Referral = () => {
  const [authUserId, setAuthUserId] = useState();
  const [value, setValue] = useState([]);
  const [emailError, setEmailError] = useState("");
  const [currentBalance, setCurrentBalance] = useState(0);
  const [totalEarned, setTotalEarned] = useState(0);
  const [referralList, setReferralList] = useState([]);
  const [topBorderjustifyTab, settopBorderjustifyTab] = useState("1");
  const [activeTab, setActiveTab] = useState("workforce");
  const [isBusiness, setIsBusiness] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [details, setDetails] = useState([]);
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [isLoadingReview, setIsLoadingReview] = useState(true); // Initially set to true
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  const business_id = JSON.parse(get_cookie("authUser")).business_id;
  const topBorderJustifytoggle = (tab) => {
    if (topBorderjustifyTab !== tab) {
      settopBorderjustifyTab(tab);
    }
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const toggleInviteModal = () => {
    setIsInviteModalOpen(!isInviteModalOpen);
  };

  const handleChipsChange = (chips) => {
    setValue(chips);
    setEmailError("");
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Already Invited":
        return "text-warning";
      case "Already Registered":
        return "text-warning";
      case "Invite sent":
        return "text-success";
      default:
        return "text-black"; // Default color or no additional class
    }
  };

  const getBadgeStatus = (status) => {
    switch (status) {
      case "Successful":
        return "bg-success";
      case "Already Registered":
        return "bg-warning";
      case "Invited":
        return "bg-success";
      case "Expired":
        return "bg-danger";
    }
  };
  useEffect(() => {
    axios.get("/get-user-details").then((res) => {
      console.log(res);
      setAuthUserId(res.id);
      getReferralList(res.id, activeTab);
      getRewardList(res.id);
    });
  }, []);

  const getReferralList = (id, referral_type) => {
    console.log(id, activeTab);
    setIsLoadingReview(true); // Show loader before API call
    let formData = {
      user_id: id,
      business_id: business_id,
      referral_type: referral_type,
    };
    axios
      .post("list-referral-invites", formData)
      .then((res) => {
        console.log(res);
        setReferralList(res.data);
      })
      .then((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoadingReview(false); // Hide loader after data is fetched
      });
  };

  const getRewardList = (id) => {
    let formData = {
      user_id: id,
      business_id: business_id,
    };
    axios
      .post("get-referral-rewards-data", formData)
      .then((res) => {
        console.log(res);
        setCurrentBalance(res.data.referral_current_balance);
        setTotalEarned(res.data.referral_total_earned);
      })
      .then((err) => {
        console.log(err);
      });
  };

  // const sendInvitation = (resend_inv_email) => {
  //   console.log(value, resend_inv_email);
  //   let formData;

  //   if (resend_inv_email) {
  //     console.log("resend_inv not null");
  //     formData = {
  //       user_id: authUserId,
  //       emails: [resend_inv_email],
  //       referral_type: activeTab,
  //       business_id: business_id,
  //       type: "resend",
  //     };
  //   } else {
  //     // Filter out invalid emails
  //     console.log("resend_inv is null");
  //     const validEmails = value.filter((email) => validateEmail(email));

  //     if (validEmails.length === 0) {
  //       setEmailError("Please enter valid email addresses.");
  //       return; // Stop execution if no valid emails
  //     }

  //     if (validEmails.length !== value.length) {
  //       setEmailError(
  //         "Some email addresses were invalid and skipped: " +
  //           value.filter((email) => !validateEmail(email)).join(", ")
  //       );
  //     } else {
  //       setEmailError("");
  //     }
  //     formData = {
  //       user_id: authUserId,
  //       business_id: business_id,
  //       emails: validEmails,
  //       referral_type: activeTab,
  //     };
  //   }
  //   console.log(formData, "form data");
  //   axios
  //     .post("send-invitation", formData)
  //     .then((res) => {
  //       console.log(res);
  //       toast.success(res.message);
  //       if (res.message == "Invitations processed successfully") {
  //         getReferralList(authUserId, activeTab);
  //       }

  //       getReferralList(authUserId, activeTab);
  //       if (resend_inv_email) {
  //         toast.success(res.toast);
  //       } else {
  //         setDetails(res.details);
  //         setIsInviteModalOpen(true);
  //         setValue([]);
  //       }
  //     })
  //     .then((err) => {
  //       console.log(err);
  //     });
  // };

  const sendInvitation = async (resend_inv_email) => {
    console.log("Value:", value, "Resend Email:", resend_inv_email);
    setIsButtonClicked(true); // Disable the button

    let formData;

    // Check if value is empty and show a message
    if (value.length === 0) {
      setEmailError("Enter emails, then press Enter or comma to add them.");
      setIsButtonClicked(false); // Re-enable the button after showing the error
      return; // Stop further execution
    }

    if (resend_inv_email) {
      console.log("Resending invitation...");
      formData = {
        user_id: authUserId,
        emails: [resend_inv_email],
        referral_type: activeTab,
        business_id: business_id,
        type: "resend",
      };
    } else {
      console.log("Sending new invitations...");
      // Filter out invalid emails
      const validEmails = value.filter((email) => validateEmail(email));

      if (validEmails.length === 0) {
        setEmailError("Please enter valid email addresses.");
        setIsButtonClicked(false); // Re-enable the button
        return; // Stop execution if no valid emails
      }

      if (validEmails.length !== value.length) {
        setEmailError(
          `Some email addresses were invalid and skipped: ${value
            .filter((email) => !validateEmail(email))
            .join(", ")}`
        );
      } else {
        setEmailError(""); // Clear any previous error
      }

      formData = {
        user_id: authUserId,
        business_id: business_id,
        emails: validEmails,
        referral_type: activeTab,
      };
    }

    console.log("Form Data:", formData);

    try {
      const res = await axios.post("send-invitation", formData);
      console.log("Response:", res);

      if (res.message === "Invitations processed successfully") {
        getReferralList(authUserId, activeTab);
      }

      if (resend_inv_email) {
        toast.success(res.toast);
      } else {
        setDetails(res.details);
        setIsInviteModalOpen(true);
        setValue([]); // Reset email input field
      }
    } catch (err) {
      console.error("Error:", err);
      toast.error("Something went wrong. Please try again.");
    } finally {
      setIsButtonClicked(false); // Re-enable the button
    }
  };

  const validateEmail = (value) => {
    // Simple email format validation
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
      // Display an error message
      setEmailError("Please enter a valid email address");
      return false;
    }
    setEmailError("");
    return true;
  };
  return (
    <div className="page-content ">
      <Card className="pe-0">
        <CardBody className="pe-0 pt-0">
          <Row className="parallelogram mb-4 pe-2 me-0">
            <Col xl={6}>
              <h3 className="text-dark mt-2" style={{ lineHeight: "inherit" }}>
                {" "}
                Boost your Business <br /> with our Referral Program
              </h3>
              <h5 className="text-muted mt-3">
                Earn rewards while growing our community!
              </h5>
              <Button
                className="btn btn-signup px-4 mt-2 "
                onClick={() => {
                  setIsModalOpen(true);
                }}
              >
                Learn more
              </Button>
            </Col>

            <Col xl={6} md={6} className="mt-4">
              <Row className="justify-content-end">
                <Col xl={5}>
                  <Card className="card-animate ">
                    <CardBody>
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1">
                          <p
                            className={
                              "text-uppercase fw-medium mb-0 text-muted"
                            }
                          >
                            Current Balance
                          </p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between mt-4">
                        <div>
                          <h4 className="fs-22 fw-semibold ff-secondary mb-0  ">
                            <span
                              className="counter-value"
                              data-target="559.25"
                            >
                              <CountUp
                                start={0}
                                prefix={"$"}
                                // suffix={item.suffix}
                                // separator={item.separator}
                                end={currentBalance}
                                decimals={2}
                                duration={4}
                              />
                            </span>
                          </h4>
                        </div>
                        <div className="avatar-xs flex-shrink-0">
                          <span className="avatar-title rounded fs-3 bg-soft-warning">
                            <i className="bx bx-dollar-circle  text-warning"></i>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col xl={5}>
                  <Card className="card-animate ">
                    <CardBody>
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1">
                          <p
                            className={
                              "text-uppercase fw-medium mb-0 text-muted"
                            }
                          >
                            Total Earned
                          </p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between mt-4">
                        <div>
                          <h4 className="fs-22 fw-semibold ff-secondary mb-0  ">
                            <span
                              className="counter-value"
                              data-target="559.25"
                            >
                              <CountUp
                                start={0}
                                prefix={"$"}
                                // suffix={item.suffix}
                                // separator={item.separator}
                                end={totalEarned}
                                decimals={2}
                                duration={4}
                              />
                            </span>
                          </h4>
                        </div>
                        <div className="avatar-xs flex-shrink-0">
                          <span className="avatar-title rounded fs-3 bg-soft-success">
                            <i className="bx bx-dollar-circle  text-success"></i>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
          <div className="mt-5"></div>
          <div className=" mt-5 d-flex justify-content-center">
            <Nav
              tabs
              className="nav nav-tabs nav-border-top nav-border-top-primary mt-3 mb-3"
            >
              {[
                { id: "1", label: "Invite Workforce", color: "#ff5fb4" },
                { id: "2", label: "Invite Business", color: "#865ce2" },
              ].map((tab) => (
                <NavItem key={tab.id}>
                  <NavLink
                    style={{
                      cursor: isButtonClicked ? "not-allowed" : "pointer",
                      color:
                        topBorderjustifyTab === tab.id ? tab.color : "inherit",
                      borderTopColor:
                        topBorderjustifyTab === tab.id
                          ? tab.color
                          : "transparent",
                    }}
                    className={classnames({
                      active: topBorderjustifyTab === tab.id,
                    })}
                    onMouseEnter={(e) => {
                      if (topBorderjustifyTab !== tab.id && !isButtonClicked)
                        e.target.style.borderTopColor = "#e6e6e6";
                    }}
                    onMouseLeave={(e) => {
                      if (topBorderjustifyTab !== tab.id && !isButtonClicked)
                        e.target.style.borderTopColor = "transparent";
                    }}
                    onClick={() => {
                      if (isButtonClicked) return; // Prevent tab switch if the button is clicked

                      topBorderJustifytoggle(tab.id);
                      setIsBusiness(tab.id === "2");
                      setActiveTab(tab.id === "1" ? "workforce" : "business");
                      getReferralList(
                        authUserId,
                        tab.id === "1" ? "workforce" : "business"
                      );
                    }}
                  >
                    {tab.label}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
            {/* <Nav
              tabs
              className="nav nav-tabs nav-border-top nav-border-top-primary mt-3 mb-3"
            >
              {[
                { id: "1", label: "Invite Workforce", color: "#ff5fb4" },
                { id: "2", label: "Invite Business", color: "#865ce2" },
              ].map((tab) => (
                <NavItem key={tab.id}>
                  <NavLink
                    style={{
                      cursor: "pointer",
                      color:
                        topBorderjustifyTab === tab.id ? tab.color : "inherit",
                      borderTopColor:
                        topBorderjustifyTab === tab.id
                          ? tab.color
                          : "transparent",
                    }}
                    className={classnames({
                      active: topBorderjustifyTab === tab.id,
                    })}
                    onMouseEnter={(e) => {
                      if (topBorderjustifyTab !== tab.id)
                        e.target.style.borderTopColor = "#e6e6e6";
                    }}
                    onMouseLeave={(e) => {
                      if (topBorderjustifyTab !== tab.id)
                        e.target.style.borderTopColor = "transparent";
                    }}
                    onClick={() => {
                      topBorderJustifytoggle(tab.id);
                      setIsBusiness(tab.id === "2");
                      setActiveTab(tab.id === "1" ? "workforce" : "business");
                      getReferralList(
                        authUserId,
                        tab.id === "1" ? "workforce" : "business"
                      );
                    }}
                  >
                    {tab.label}
                  </NavLink>
                </NavItem>
              ))}
            </Nav> */}
            {/* <Nav
              tabs
              className="nav nav-tabs nav-border-top nav-border-top-primary mt-3 mb-3"
            >
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: topBorderjustifyTab === "1",
                  })}
                  onClick={() => {
                    topBorderJustifytoggle("1");
                    setIsBusiness(false);
                    setActiveTab("workforce");
                    getReferralList(authUserId, "workforce");
                  }}
                >
                  Invite Workforce
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: topBorderjustifyTab === "2",
                  })}
                  onClick={() => {
                    topBorderJustifytoggle("2");
                    setIsBusiness(true);
                    setActiveTab("business");
                    getReferralList(authUserId, "business");
                  }}
                >
                  Invite Business
                </NavLink>
              </NavItem>
            </Nav> */}
          </div>
          <Row className="mt-4 text-center">
            <div className="m-auto w-75">
              <Chips
                value={value}
                onChange={handleChipsChange}
                suggestions={[]}
                placeholder="Enter email addresses"
                fromSuggestionsOnly={false}
                createChipKeys={["Enter", ","]}
                //theme={theme}
              />
            </div>
          </Row>
          <div className="text-center mt-3 ">
            {emailError && <p style={{ color: "red" }}>{emailError}</p>}
            {/* Rest of your form */}
          </div>
          <div className="text-muted mt-1 m-auto text-center">
            Seperate emails using a Comma or Enter
          </div>
          <Row>
            <div className="mt-3 text-center">
              {/* <button
              className={`btn invite-search-btn ${isBusiness ? "btn-primary" : "btn-pink"} `}
              type="button"
              id="button-addon2"
              onClick={() => sendInvitation()}
            >
              Send Invitation
            </button> */}
              {console.log(isBusiness, "ISBUSINESS")}
              <button
                className="btn invite-search-btn"
                disabled={isButtonClicked}
                type="button"
                id="button-addon2"
                onClick={() => sendInvitation()}
                style={{
                  backgroundColor: isBusiness ? "#6f47c6" : "#fa149e", // Set background color based on isBusiness
                  color: isButtonClicked ? "white" : "white", // Change text color if button is clicked
                }}
              >
                {isButtonClicked ? (
                  <>
                    <Spinner size="sm" className="me-2" />
                    Send Invitation
                  </>
                ) : (
                  "Send Invitation"
                )}
              </button>
            </div>
          </Row>
          <div
            className="table-responsive m-auto w-75    mt-5"
            style={{ maxHeight: "400px", overflowY: "auto" }}
          >
            <Table
              className="table-striped table-bordered invite-search-table table-nowrap align-middle mt-2 mb-0"
              style={{
                borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
              }}
            >
              <thead
                className={` text-white ${
                  isBusiness ? "bg-primary" : "bg-pink"
                }`}
                style={{
                  borderTopLeftRadius: "20px",
                  border: "0px",
                  borderTopRightRadius: "20px",
                }}
              >
                <tr>
                  <th style={{ borderTopLeftRadius: "20px" }} scope="col">
                    Email address
                  </th>
                  <th scope="col">Invited on</th>
                  <th scope="col">Status</th>
                  <th
                    style={{ borderTopRightRadius: "20px" }}
                    scope="col"
                    className="text-right"
                  >
                    Action
                  </th>
                </tr>
              </thead>
              {isLoadingReview ? (
                <tr>
                  <td colSpan="4" className="text-center">
                    <div
                      style={{
                        background: "rgba(255, 255, 255, 0.8)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "20px",
                      }}
                    >
                      {/* Dynamically set the spinner color */}
                      <Spinner
                        style={{
                          color:
                            activeTab === "workforce" ? "#ff5fb4" : "#865ce2", // Dynamic color based on activeTab
                        }}
                      >
                        Loading...
                      </Spinner>
                    </div>
                  </td>
                </tr>
              ) : referralList?.length === 0 ? (
                <tr>
                  <td colSpan="4" className="text-center p-3">
                    <span
                      style={{
                        color: "#6c757d", // Bootstrap standard grey for text
                        fontSize: "14px",
                      }}
                    >
                      No records found
                    </span>
                  </td>
                </tr>
              ) : (
                <tbody>
                  {referralList?.map((item, index) => (
                    <tr key={index}>
                      <td>{item.referral_email}</td>
                      <td>{item.date_sent}</td>

                      <td>
                        <span
                          className={` badge ${getBadgeStatus(item.status)} `}
                        >
                          {item.status}
                        </span>
                      </td>
                      <td className="text-right">
                        {item?.status == "Expired" ? (
                          <div
                            className=" fs-18 align-items-center hstack gap-2 text-success "
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              sendInvitation(item.referral_email);
                            }}
                          >
                            <i className=" bx bx-refresh"> </i>
                            <span className="fs-12">Resend</span>
                          </div>
                        ) : null}
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </Table>
          </div>
          {/* <Modal
            className="zoomIn"
            id="addSeller"
            size="lg"
            isOpen={isModalOpen}
            toggle={toggleModal}
            centered
          >
            <ModalHeader             toggle={toggleModal}
            >
            <h4 style={{color: "#6f47c6"}}> Boost Your Business with Our Referral Program</h4>
            </ModalHeader>
            <ModalBody>
              <div className=" align-items-center ">
                <h6 className="text-black">
                  {" "}
                  Earn rewards while growing our community.{" "}
                </h6>
                <p>
                  {" "}
                  Welcome to the Get Rostered Referral Center! Our program is
                  designed to help you expand your network and reduce your costs
                  simultaneously. Here’s how you can benefit and contribute to
                  our growing community:{" "}
                </p>
                <h6 className="text-black"> How It Works:</h6>
                <span className="hstack gap-2 align-items-center ps-5">
                  {" "}
                  <h6 className="text-black mb-0">
                    Invite Workforce Users:{" "}
                  </h6>{" "}
                  <p className="mb-0">
                    {" "}
                    For every new workforce user who signs up through your
                    referral, you earn 1 point.{" "}
                  </p>{" "}
                </span>
                <span className="hstack gap-2 align-items-center ps-5">
                  <h6 className="text-black mb-0">Invite Businesses: </h6>{" "}
                  <p className="mb-0">
                    {" "}
                    For every business that signs up using your referral link,
                    you earn 5 points.
                  </p>{" "}
                </span>
                <h6 className="text-black mb-0 mt-2"> Rewards:</h6>
                <p className="mb-0 ps-5">
                  {" "}
                  Each point you earn is equivalent to $3.
                </p>
                <p className="ps-5">
                  Accumulated points can be used to pay for your monthly
                  subscription costs, making our services more rewarding.{" "}
                </p>
                <h6 className="text-black"> Invite Today: </h6>
                <p className="ps-5">
                  To invite a workforce user or another business, simply click
                  the appropriate 'Invite' button below and enter their email
                  addresses. It's that easy!{" "}
                </p>
                <p>
                  {" "}
                  With our Referral Center, not only do you save on your
                  expenses, but you also play a pivotal role in shaping a
                  collaborative and resourceful community. Start inviting today
                  and watch your rewards grow as your network expands!{" "}
                </p>
                <b className="text-black">Note: </b> While not-for-profit
                organisations and charities are encouraged to invite workforce
                users and other businesses via the referral program, they are
                not eligible to earn reward points, as Get Rostered services are
                provided to these entities free of charge.
              </div>
            </ModalBody>
          </Modal> */}
          <Modal
            className="zoomIn"
            id="addSeller"
            size="lg"
            isOpen={isModalOpen}
            toggle={toggleModal}
            centered
            style={{
              overflow: "hidden",
            }}
          >
            <ModalHeader
              style={{
                backgroundColor: "#6f47c6",
                padding: "20px",
              }}
            >
              <h4 style={{ fontSize: "1.5rem", margin: 0, color: "#ffffff" }}>
                Boost Your Business with Our Referral Program
              </h4>
            </ModalHeader>

            <ModalBody>
              <div
                className="align-items-center"
                style={{
                  padding: "20px",
                  lineHeight: "1.6",
                  fontFamily: '"Segoe UI", Tahoma, Geneva, Verdana, sans-serif',
                }}
              >
                <h6 style={{ color: "#333", fontWeight: "bold" }}>
                  Earn rewards while growing our community.
                </h6>
                <p style={{ marginBottom: "15px" }}>
                  Welcome to the Get Rostered Referral Center! Our program is
                  designed to help you expand your network and reduce your costs
                  simultaneously. Here’s how you can benefit and contribute to
                  our growing community:
                </p>
                <h6
                  style={{
                    color: "#333",
                    fontWeight: "bold",
                    marginBottom: "10px",
                  }}
                >
                  How It Works:
                </h6>

                <div
                  style={{
                    paddingLeft: "20px",
                    marginBottom: "15px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <h6
                      style={{
                        color: "#fa149e",
                        fontWeight: "bold",
                        margin: 0,
                        whiteSpace: "nowrap", // Prevent line breaks
                      }}
                    >
                      Invite Workforce Users:
                    </h6>
                    <p
                      style={{
                        margin: 0,
                        paddingLeft: "10px", // Adds a little space between the heading and content
                      }}
                    >
                      For every new workforce user who signs up through your
                      referral, you earn 1 point.
                    </p>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <h6
                      style={{
                        color: "#6f47c6",
                        fontWeight: "bold",
                        margin: 0,
                        whiteSpace: "nowrap", // Prevent line breaks
                      }}
                    >
                      Invite Businesses:
                    </h6>
                    <p
                      style={{
                        margin: 0,
                        paddingLeft: "10px", // Adds a little space between the heading and content
                      }}
                    >
                      For every business that signs up using your referral link,
                      you earn 5 points.
                    </p>
                  </div>
                </div>

                <h6
                  style={{
                    color: "#333",
                    fontWeight: "bold",
                    marginBottom: "10px",
                  }}
                >
                  Rewards:
                </h6>
                <div style={{ paddingLeft: "20px", marginBottom: "15px" }}>
                  <p style={{ margin: 0 }}>
                    Each point you earn is equivalent to $3.
                  </p>
                  <p>
                    Accumulated points can be used to pay for your monthly
                    subscription costs, making our services more rewarding.
                  </p>
                </div>
                <h6
                  style={{
                    color: "#333",
                    fontWeight: "bold",
                    marginBottom: "10px",
                  }}
                >
                  Invite Today:
                </h6>
                <p style={{ paddingLeft: "20px", marginBottom: "15px" }}>
                  To invite a workforce user or another business, simply click
                  the appropriate 'Invite' button below and enter their email
                  addresses. It's that easy!
                </p>
                <p style={{ marginBottom: "15px" }}>
                  With our Referral Center, not only do you save on your
                  expenses, but you also play a pivotal role in shaping a
                  collaborative and resourceful community. Start inviting today
                  and watch your rewards grow as your network expands!
                </p>
                <b
                  style={{
                    color: "#333",
                    display: "block",
                    marginBottom: "6px",
                  }}
                >
                  Note:
                </b>
                <p>
                  While not-for-profit organisations and charities are
                  encouraged to invite workforce users and other businesses via
                  the referral program, they are not eligible to earn reward
                  points, as Get Rostered services are provided to these
                  entities free of charge.
                </p>
              </div>
            </ModalBody>
          </Modal>

          <Modal
            className="zoomIn"
            id="displayInvitesSent"
            size="md"
            isOpen={isInviteModalOpen}
            toggle={toggleInviteModal}
            centered
          >
            <ModalHeader toggle={toggleInviteModal}>
              <h4 className="text-primary">Email Invitation Status</h4>
            </ModalHeader>
            <ModalBody>
              {details?.map((detail, index) => (
                // <Row key={index} className="mt-2">
                //   <Col lg={7}>{detail.email}</Col>
                //   <Col lg={5} className={getStatusColor(detail.status)}>
                //     {detail.status}
                //   </Col>
                // </Row>
                <Row key={index} className="mt-2 align-items-center">
                  <Col lg={7} className="text-start">
                    {detail.email}
                  </Col>
                  <Col
                    lg={5}
                    className={`text-end ${getStatusColor(detail.status)}`}
                  >
                    {detail.status}
                  </Col>
                </Row>
              ))}
            </ModalBody>
          </Modal>

          <ToastContainer closeButton={false} limit={1} theme="light" />
        </CardBody>
      </Card>
    </div>
  );
};
