import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
  FormFeedback,
  Alert,
  Spinner,
} from "reactstrap";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { useLocation } from "react-router-dom";
//redux
import { add_cookie, add_permissions_cookie } from "../../helpers/get_cookie";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import halfLogo from "../../assets/images/half-logo.png";
import logo from "../../assets/images/logo_purple_trans.png";
import { useNavigate } from "react-router-dom";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

//Social Media Imports
// import { GoogleLogin } from "react-google-login";
// import TwitterLogin from "react-twitter-auth"
// import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
// actions
import { loginUser, socialLogin, resetLoginFlag } from "../../store/actions";

// import logoRoster from "../../assets/images/logopurple.png";
import logoPurple from "../../assets/images/logopurple.png";

// import logoPurple from from "../../assets/images/logopurple.png";
//Import config
import { facebook, google } from "../../config";
import withRouter from "../../Components/Common/withRouter";
//import images

const Login = (props) => {
  const dispatch = useDispatch();
  const { user, errorMsg, loading, error } = useSelector((state) => ({
    user: state.Account.user,
    errorMsg: state.Login.errorMsg,
    loading: state.Login.loading,
    error: state.Login.error,
  }));
  const [isLoading, setIsLoading] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const navigate = useNavigate();
  const [userLogin, setUserLogin] = useState([]);
  const [passwordShow, setPasswordShow] = useState(false);
  const [result, setResult] = useState(true);
  const [formErrors, setFormErrors] = useState({});
  const [errLogin, setErrLogin] = useState("");
  const [isErr, setIsErr] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    remember: false,
  });

  const signIn = (res, type) => {
    if (type === "google" && res) {
      const postData = {
        name: res.profileObj.name,
        email: res.profileObj.email,
        token: res.tokenObj.business_access_token,
        idToken: res.tokenId,
      };
      dispatch(socialLogin(postData, props.router.navigate, type));
    } else if (type === "facebook" && res) {
      const postData = {
        name: res.name,
        email: res.email,
        token: res.accessToken,
        idToken: res.tokenId,
      };
      dispatch(socialLogin(postData, props.router.navigate, type));
    }
  };

  //handleGoogleLoginResponse
  const googleResponse = (response) => {
    signIn(response, "google");
  };

  const facebookResponse = (response) => {
    signIn(response, "facebook");
  };

  useEffect(() => {
    // Check if there is a stored URL for redirecting after session expiration
    const redirectUrl = localStorage.getItem("expiredSessionRedirectUrl");
    console.log(redirectUrl);
  }, []);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        dispatch(resetLoginFlag());
      }, 3000);
    }
  }, [dispatch, error]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();

    let isChecked = document.getElementById("auth-remember-check").checked;
    // Basic validation
    if (!formData.email || !formData.password) {
      setFormErrors({
        email: !formData.email ? "Please enter your email" : "",
        password: !formData.password ? "Please enter your password" : "",
        remember: isChecked,
      });
      return;
    }

    // Clear previous errors
    setFormErrors({});

    // Axios request
    try {
      const response = await axios.post("/login", formData);
      const Bearer_token = response.business_access_token;
      // Set the bearer token in the headers for the subsequent request
      const headers = {
        Authorization: `Bearer ${Bearer_token}`,
      };
      const payload = {
        user_id: response?.user_id,
        business_id : response.business_id,
      };

      if (response.status_code == 200) {
        const rolesAndPermissionsRes = await axios.post(
          "/get-roles-and-permissions",
          payload,
          { headers }
        );

        // Check for the permissions"
        const permissions = rolesAndPermissionsRes.roles_and_permissions
          .map((role) => role.permission_names)
          .flat();
        // Adding permissions to cookie
        if (rolesAndPermissionsRes) {
          add_permissions_cookie(permissions);
        }
        // console.log("Permissions:", permissions);

        add_cookie(response, isChecked);
        if (response.profile_status == 0) {
          navigate("/business-profile");
        } else {
          {
            permissions?.includes("view_rosters")
              ? navigate("/job-Vacancies")
              : navigate("/business_settings");
          }
        }
      } else if (response.status_code == 202) {
        toast.error(response.message);
        setIsLoading(false);
      } else if (response.status_code == 900) {
        //System User Code
        if(response.redirect_page === "pagebusiness_list"){
          setTimeout(() => {
            navigate("/business-list",{ state: { formData: response,isChecked: isChecked} })
          }, 2000);
        }
        const rolesAndPermissionsRes = await axios.post(
          "/get-roles-and-permissions",
          payload,
          { headers }
        );

        // Check for the permissions"
        const permissions = rolesAndPermissionsRes.roles_and_permissions
          .map((role) => role.permission_names)
          .flat();
        // Adding permissions to cookie
        if (rolesAndPermissionsRes) {
          add_permissions_cookie(permissions);
        }
        add_cookie(response, isChecked);

        {
          permissions?.includes("view_rosters")
            ? navigate("/roster_lounge")
            : navigate("/business_settings");
        }

        // console.log("business_settings", response);
        // navigate("/roster_lounge");
      } else if (response.message === "User is not confirmed.") {
        setIsErr(true);
        // Retrieve full registration data from localStorage
        const storedFormData = JSON.parse(localStorage.getItem('registrationFormData'));
        const mergedFormData = { ...storedFormData, ...formData };
        console.log(mergedFormData,'mergedFormData123');
        axios
          .post("/resend-otp", { email: formData.email })
          .then((res) => {
            if (res.status === "error") {
              toast.error(res.message, { autoClose: 3000 });
              return;
            } else {
              toast.warning(
                "User is not verified! Redirecting to verification page...",
                { autoClose: 2000 }
              );
              setTimeout(() => {
                navigate("/twoStepVerify", { state: { formData: mergedFormData  } });
              }, 2000); // Delay the navigation by 2 seconds
            }
            console.log(res);
          })
          .catch((error) => {
            // Handle any unexpected errors during the axios call
            toast.error(
              "An error occurred while resending OTP. Please try again.",
              { autoClose: 3000 }
            );
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else if (response.message === "Incorrect username or password.") {
        setIsErr(true);
        // setErrLogin("Incorrect user email or password!");
        toast.error("Incorrect user email or password!");
        setIsLoading(false);
      } else if (
        response.status_code == 404 &&
        response.message == "User not found"
      ) {
        toast.error(response.message);
        setIsLoading(false);
      } else {
        console.log("An unknown error has occured");
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  useEffect(() => {
    // Function to fetch initial permissions data
    // const fetchPermissions = async () => {
    //   try {

    //   } catch (error) {
    //     console.error("Error fetching permissions:", error);
    //   }
    // };

    // Function to handle SSE messages
    const handleSSEMessage = (event) => {
      const data = JSON.parse(event.data);
      // Update permissions in state with the received data
      setPermissions(data);
    };

    // Establish SSE connection
    const eventSource = new EventSource("/api/send-permissions-update");
    // Event listener for incoming SSE messages
    eventSource.onmessage = handleSSEMessage;

    // Fetch initial permissions data
    // fetchPermissions();

    // Cleanup SSE connection on component unmount
    return () => {
      eventSource.close();
    };
  }, []);

  document.title = "Login | Get Rostered ";

  return (
    <React.Fragment>
      <ParticlesAuth className="">
        <div className="d-flex position-relative">
          <img src={logo} alt="logo" className="floating-logo logo1" />
          <img src={logo} alt="logo" className="floating-logo logo2" />
          <img src={logo} alt="logo" className="floating-logo logo3" />
          <img src={logo} alt="logo" className="floating-logo logo4" />
          <Container className="mt-lg-3 ">
            <Row className="justify-content-center">
              <Link
                to="/"
                className="text-center mb-4 d-inline-block auth-logo"
              >
                <img src={logoPurple} alt="" height="55" />
              </Link>
              <Col md={8} lg={6} xl={5}>
                <Card
                  className="login-card mt-4"
                  style={{ borderRadius: "40px" }}
                >
                  <CardBody className="p-5">
                    <div className="text-center mt-0 mb-2 ">
                      <div>
                        <Label
                          style={{
                            fontSize: "22px",
                            fontWeight: "700",
                            fontFamily: "Poppins",
                          }}
                        >
                          Let's get you Logged in!
                        </Label>
                      </div>
                    </div>
                    <div className="text-center mb-4">
                      <Label
                        style={{ fontSize: "12px", fontFamily: "Poppins" }}
                      >
                        Enter your information below
                      </Label>
                    </div>

                    {errorMsg && errorMsg ? (
                      <Alert color="danger"> {errorMsg} </Alert>
                    ) : null}
                    <div className="p-2 mt-2">
                      <form onSubmit={handleSubmit}>
                        <div className="mb-3 form-group ">
                          <div className="form-icon">
                            <Input
                              name="email"
                              className={
                                "form-control ps-5 pe-5 form-control-icon" +
                                (formErrors.email ? " is-invalid" : "")
                              }
                              placeholder="Enter email"
                              type="email"
                              id="email"
                              onChange={handleInputChange}
                              value={formData.email}
                              invalid={
                                formErrors.email !== undefined &&
                                formErrors.email !== ""
                              }
                            />
                            <i
                              className="ri-mail-fill"
                              style={{ color: "grey" }}
                            ></i>
                          </div>
                          {formErrors.email && (
                            <div className="invalid-feedback">
                              {formErrors.email}
                            </div>
                          )}
                        </div>

                        <div className="position-relative auth-pass-inputgroup mb-3">
                          <i
                            className="ri-lock-2-fill position-absolute mt-2 ms-3 icon-left"
                            style={{ color: "grey" }}
                          ></i>
                          <Input
                            name="password"
                            value={formData.password}
                            type={passwordShow ? "text" : "password"}
                            className="form-control ps-5 pe-5"
                            placeholder="Enter Password"
                            onChange={handleInputChange}
                            invalid={
                              formErrors.password !== undefined &&
                              formErrors.password !== ""
                            }
                          />
                          {formErrors.password && (
                            <FormFeedback type="invalid">
                              {formErrors.password}
                            </FormFeedback>
                          )}
                          <button
                            className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted me-2"
                            type="button"
                            id="password-addon"
                            onClick={() => setPasswordShow(!passwordShow)}
                          >
                            <i className="ri-eye-fill align-middle"></i>
                          </button>
                        </div>

                        <div className="form-check">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            id="auth-remember-check"
                          />
                          <Label
                            className="form-check-label"
                            htmlFor="auth-remember-check"
                          >
                            Remember me
                          </Label>
                          <div className="float-end">
                            <Link
                              to="/forgot-password"
                              className="text-bold"
                              style={{
                                fontWeight: "600",
                                color: "black",
                                fontSize: 12,
                                color: "rgba(0, 46, 70, 1)",
                              }}
                            >
                              Forgot password?
                            </Link>
                          </div>
                        </div>
                        {isErr && (
                          <div className="text-bold mt-3 ">
                            <b className="text-danger">
                              <p>{errLogin}</p>
                            </b>
                          </div>
                        )}

                        <div className="mt-4">
                          <Button
                            style={{
                              background: "#48008A",
                              borderRadius: "10px",
                            }}
                            color="primary"
                            disabled={isLoading ? true : false}
                            className="text-white w-100"
                            type="submit"
                          >
                            {isLoading ? (
                              <Spinner size="sm" className="me-2">
                                {" "}
                                Loading...{" "}
                              </Spinner>
                            ) : null}
                            Login
                          </Button>
                        </div>
                        <div className="mt-3 mb-1 text-center">
                          <p className="mb-0">
                            Don't have an account?{" "}
                            <Link
                              to="/register"
                              className="fw-semibold"
                              style={{
                                fontWeight: "600",
                                color: "rgba(0, 46, 70, 1)",
                              }}
                            >
                              {" "}
                              Signup{" "}
                            </Link>{" "}
                          </p>
                        </div>
                      </form>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <ToastContainer />
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default withRouter(Login);
