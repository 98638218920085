import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Accordion,
  AccordionItem,
  Collapse,
  Label,
  Input,
  Button,
  UncontrolledTooltip,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Badge,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  Alert,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import axios from "axios";
import { get_cookie } from "../../../helpers/get_cookie";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import info from "../../../assets/images/info.png";
import visaCard from "../../../assets/images/card_brand/visa.png";
import amex from "../../../assets/images/card_brand/american_express.png";
import union from "../../../assets/images/card_brand/union_pay.jpg";
import master from "../../../assets/images/card_brand/master_card.png";
import atmCard from "../../../assets/images/atm-card.png";
import diners from "../../../assets/images/card_brand/diners_club_international.png";
import discover from "../../../assets/images/card_brand/discover.jpg";
import AddNewPayment from "../../Billing/AddNewPayment";
import CardDeleteModal from "../../Billing/DeleteModal";
import { toast, ToastContainer } from "react-toastify";
import CheckoutForm from "../CheckoutForm";
import { stripe } from "../../../config";

const stripePromise = loadStripe(stripe.STRIPE_LIVE);

export const InstantPayout = () => {
  const business_id = JSON.parse(get_cookie("authUser")).business_id;
  const user_id = JSON.parse(get_cookie("authUser")).user_id;
  const [cardDetails, setCardDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [authUserId, setAuthUserId] = useState();
  const [showAddCard, setShowAddCard] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(0);
  const [modal1, setModal1] = useState(false);
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [showCheckout, setShowCheckout] = useState(false);
  const [billingInfo, setBillingInfo] = useState([]);
  const [showDefaultErr, setShowDefaultErr] = useState(false);
  const [ipEnabled, setIpEnabled] = useState("");
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [modal_assignModal, setmodal_assignModal] = useState(false);
  const card_logos = {
    visa: visaCard,
    amex: amex,
    mastercard: master,
    unionpay: union,
    diners: diners,
    discover: discover,
  };
  useEffect(() => {
    axios
      .get("/get-user-details")
      .then((res) => {
        setAuthUserId(res.id);
      })
      .then((err) => {
        console.log(err);
      });
  }, []);
  const archiveModal = (roster_id, status) => {
    setmodal_assignModal(true);
    setIsModalLoading(false);
  };
  function tog_assignModal() {
    setmodal_assignModal(!modal_assignModal);
  }
  const getPayoutData = () => {
    var formData = {
      user_id: user_id,
      business_id: business_id,
    };
    axios
      .post("/get-payout-data", formData)
      .then((res) => {
        setBillingInfo(res.billingInfo);
        setIpEnabled(res.billingInfo.ip_enabled);
      })
      .then((err) => {
        console.log(err);
      });
  };
  const getCardLogo = (brand) => {
    return card_logos[brand] || null;
  };
  const handleCardDelete = (id) => {
    setDeleteId(id);
    setDeleteModal(true);
  };
  const handleUpgradeClick = () => {
    setShowCheckout(true);
  };
  const toggleModal = () => {
    setShowCheckout(!showCheckout);
  };
  const toggleDefaultErr = () => {
    setShowDefaultErr(!showDefaultErr);
  };
  const getCardDetails = () => {
    console.log(ipEnabled);
    setIsLoading(true);
    axios
      .post("/get-payout-card-details", {
        user_id: user_id,
        business_id: business_id,
      })
      .then((res) => {
        if (res.status === "error") {
          //toast.warning(res.message);
        } else {
          console.log(res, "card details");
          setCardDetails(res.cards);
          setIsLoading(false);
          setIsSubscribed(res.is_subscribed);
          getPayoutData();
        }
      })
      .then((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };
  const handleDeletePermission = async () => {
    var deleteval = {
      user_id: authUserId,
      payment_method: deleteId,
      business_id: business_id,
      type: "InstantPayout",
    };
    axios
      .post("/delete-card", deleteval)
      .then((res) => {
        if (res.code === 400) {
          //setShowDefaultErr(true);
          toast.error(res.message);
        } else {
          toast.success(res.message, { theme: "light" });
          getCardDetails();
          getPayoutData();
        }

        setDeleteModal(false);
      })
      .catch((error) => {
        console.error("Error fetching lead types:", error);
      });
  };
  useEffect(() => {
    // Automatically set the switch based on initial value of ipEnabled
    setIsSwitchOn(ipEnabled === "Y");
  }, [ipEnabled]);

  const getMonthName = (monthNumber) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return months[monthNumber - 1];
  };

  useEffect(() => {
    getPayoutData();
    console.log(ipEnabled, "isSwitchOn");
    if (ipEnabled === "Y") {
      getCardDetails(); // Call the function here
    }
  }, [ipEnabled]);

  const toggleModal1 = () => {
    setModal1(!modal1);
  };

  const handleDefaultCard = (id) => {
    let formData = {
      payment_method: id,
      user_id: authUserId,
      business_id: business_id,
    };
    axios
      .post("/set-payout-default-card", formData)
      .then((res) => {
        console.log(res);
        getCardDetails();
        getPayoutData();
      })
      .then((err) => {
        console.log(err);
      });
  };
  const handleNewPayment = () => {
    setShowAddCard(true);
  };
  const toggleAddCard = () => {
    setShowAddCard(!showAddCard);
  };
  const handleSwitchToggle = (e) => {
    setIsSwitchOn((prev) => !prev); // Toggle the switch state
    // API call to update the IP enabled status when isSwitchOn changes
    const formData = {
      user_id: user_id,
      business_id: business_id,
      ip_enabled: isSwitchOn,
    };
    console.log(isSwitchOn);
    axios
      .post("/update-ipenabled", formData)
      .then((res) => {
        if (res.status === "success") {
          console.log("API Response:", res);
          getCardDetails(); // Update card details after API call
          getPayoutData();
          setmodal_assignModal(false);
        } else {
          toast.error(res.message);
          setmodal_assignModal(true);
        }
      })
      .catch((err) => {
        console.error("API Error:", err);
      });
  };
  return (
    <div>
      <Row>
        <Col lg={4}>
          <Card>
            <CardHeader className="pt-2 pb-2">
              <div className="d-flex align-items-center justify-content-between flex-wrap gap-2">
                <h5 className="mb-0  ">Instant Payout</h5>
                <button
                  className="btn btn ps-0 pb-1 pt-1"
                  onClick={() => {
                    toggleModal1();
                  }}
                >
                  <i className="ri-questionnaire-fill me-1 fs-18 text-primary p-0 align-bottom"></i>
                </button>
              </div>
            </CardHeader>
            <CardBody className="">
              <h6 className="">
                {" "}
                Enable instant payout{" "}
                {ipEnabled === "N" && (
                  <span id="info-icon">
                    <img src={info} height={20} alt="Info" />
                    <UncontrolledTooltip placement="right" target="info-icon">
                      Enable this button for instant payout
                    </UncontrolledTooltip>
                  </span>
                )}
              </h6>

              <div className="d-flex justify-content-between align-items-center  ">
                <div className="vstack">
                  <Label className="text-gray">
                    Allow users to receive payments instantly.
                  </Label>

              
                    
                    <div className="form-check form-switch-primary form-switch form-switch-md ">
                      <Label className="form-check-label" for="SwitchCheck2">
                        {/* Conditionally render the info icon and tooltip when card count is 0 */}
                      </Label>
                      <Input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="SwitchCheck2"
                        checked={isSwitchOn} // Controlled by state// Handle user toggle
                        //onChange={handleSwitchToggle}
                        onClick={archiveModal}
                      />
                    </div>
                    {/* <img
                      src={atmCard}
                      //  style={{position:"absolute", bottom:"10px",right:"10px"}}
                      alt=""
                      width="50px"
                    /> */}
            
                </div>
              </div>

              <ToastContainer />
            </CardBody>
          </Card>
        </Col>
        <Col lg={8}>
          <Row>
            {ipEnabled === "Y" && (
              <Col lg={12}>
                {isLoading ? (
                  <Card>
                    <CardBody>
                      <div>
                        <h3 className="card-title placeholder-glow">
                          <span className="placeholder placeholder-sm  col-2 bg-dark"></span>
                        </h3>
                        <h3 className="card-title placeholder-glow">
                          <span className="placeholder placeholder-sm  col-5 bg-dark"></span>
                        </h3>
                      </div>
                    </CardBody>
                  </Card>
                ) : (
                  <Card>
                    <CardHeader className="d-flex justify-content-between align-items-center pt-2 pb-2">
                      <h5 className="m-0">Payment Methods</h5>
                      {cardDetails?.length >= 1 ? (
                        <Button
                          className="btn btn-primary"
                          style={{ border: "0px solid black" }}
                          onClick={() => handleNewPayment()}
                        >
                          New Payment Method
                        </Button>
                      ) : (
                        <span id="disabled-payment">
                          <Button
                            className="btn btn-primary"
                            style={{ border: "0px solid black" }}
                            onClick={() => handleUpgradeClick()}
                            disabled={!isSwitchOn}
                          >
                            Add New Card
                          </Button>
                          {/*<UncontrolledTooltip
                                placement="bottom"
                                target="disabled-payment"
                              >
                                Enable the toggle button to add a new card.
                              </UncontrolledTooltip>*/}
                        </span>
                      )}
                    </CardHeader>
                    <CardBody>
                      {isLoading ? (
                        <div>
                          <h3 className="card-title placeholder-glow">
                            <span className="placeholder placeholder-sm  col-2 bg-dark"></span>
                          </h3>
                          <h3 className="card-title placeholder-glow">
                            <span className="placeholder placeholder-sm  col-5 bg-dark"></span>
                          </h3>
                        </div>
                      ) : (
                        <>
                          {cardDetails.length > 0 ? (
                            <>
                              {cardDetails?.map((detail, index) => (
                                <>
                                  <div
                                    key={index}
                                    className="d-flex justify-content-between  align-items-center"
                                  >
                                    <div
                                      className="row"
                                      style={{ flex: "auto" }}
                                    >
                                      <Col lg={1}>
                                        {detail?.brand && (
                                          <img
                                            src={getCardLogo(detail.brand)}
                                            alt={detail.brand}
                                            height={40}
                                          />
                                        )}
                                      </Col>
                                      <Col lg={11}>
                                        <div className=" ms-3 vstack">
                                          <span>
                                            {detail.brand} xxxx{detail.last4}{" "}
                                            {billingInfo.ip_payment_method_id &&
                                              billingInfo.ip_payment_method_id ===
                                                detail.id && (
                                                <span className="badge badge-soft-primary ms-2">
                                                  {" "}
                                                  {billingInfo.ip_payment_method_id
                                                    ? "Default"
                                                    : ""}
                                                </span>
                                              )}
                                          </span>
                                          <span>
                                            Expires:{" "}
                                            {getMonthName(
                                              detail.expiration_month
                                            )}{" "}
                                            {detail.expiration_year}{" "}
                                          </span>
                                        </div>
                                      </Col>
                                    </div>

                                    <div
                                      className="vstack "
                                      style={{ flex: "none" }}
                                    >
                                      {detail.payment_failed ? (
                                        <span className="badge badge-soft-danger">
                                          Payment failed
                                        </span>
                                      ) : null}
                                      <UncontrolledDropdown>
                                        <DropdownToggle
                                          tag="button"
                                          className="btn float-end btn-icon text-muted btn-sm fs-18 dropdown"
                                          type="button"
                                        >
                                          <i className="ri-more-fill"></i>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                          <li
                                            onClick={() => {
                                              handleDefaultCard(detail.id);
                                            }}
                                          >
                                            <DropdownItem
                                              disabled={
                                                billingInfo.ip_payment_method_id ===
                                                detail.id
                                              }
                                            >
                                              Set as Default
                                            </DropdownItem>
                                          </li>
                                          <li
                                            onClick={() =>
                                              handleCardDelete(detail.id)
                                            }
                                          >
                                            <DropdownItem>Delete</DropdownItem>
                                          </li>
                                        </DropdownMenu>
                                      </UncontrolledDropdown>
                                    </div>
                                  </div>
                                  {index !== cardDetails?.length - 1 && (
                                    <hr className="text-muted"></hr>
                                  )}
                                </>
                              ))}
                            </>
                          ) : (
                            <span className="text-muted">
                              No cards added yet!
                            </span>
                          )}
                        </>
                      )}
                    </CardBody>
                  </Card>
                )}
              </Col>
            )}
          </Row>
        </Col>
      </Row>

      <Modal
        className="zoomIn"
        id="addSeller1"
        size="md"
        isOpen={modal1}
        toggle={toggleModal1}
        centered
      >
        <ModalHeader toggle={toggleModal1}>
          <b style={{ fontWeight: "bold" }}>Instant Payouts</b>
        </ModalHeader>
        <ModalBody>
          <p style={{ textAlign: "justify" }}>
            Enable Instant Payout to provide your workers with the flexibility
            to receive their earnings immediately after their shifts are
            approved. By activating this feature, payments for eligible shifts
            will be pre-processed, ensuring funds are readily available for
            payout upon shift completion. To get started, simply enable Instant
            Payout and add your preferred debit or credit card to fund these
            payouts.{" "}
          </p>
          <Alert color="info">
            <p style={{ fontWeight: "bold", marginBottom: "0px" }}>Note:</p>
            <p>
              Instant Payout helps attract a reliable workforce, offering them
              financial flexibility and boosting job satisfaction. Ensure your
              funding card has sufficient balance for smoother processing.
            </p>
          </Alert>
        </ModalBody>
      </Modal>
      <CardDeleteModal
        show={deleteModal}
        onDeleteClick={handleDeletePermission}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Modal isOpen={showAddCard} toggle={toggleAddCard} centered={true}>
        <ModalHeader className="w-100">
          <div>Add New Card</div>
        </ModalHeader>
        <ModalBody>
          <Elements stripe={stripePromise}>
            <AddNewPayment
              onSuccess={() => {
                setShowAddCard(false);
                getCardDetails();
                getPayoutData();
              }}
            />
          </Elements>
        </ModalBody>
      </Modal>
      <Modal isOpen={showCheckout} toggle={toggleModal} centered={true}>
        <ModalHeader className="w-100">
          <div>Pay by Credit or Debit Card</div>
        </ModalHeader>
        <ModalBody>
          <Elements stripe={stripePromise}>
            <CheckoutForm
              onSuccess={() => {
                setShowCheckout(false);
                getCardDetails();
                getPayoutData();
                // getSubscriptionDetails(authUserId);
                toast.success("Subscription successful");
              }}
            />
          </Elements>
        </ModalBody>
      </Modal>

      {isModalLoading ? (
        <Modal
          size="md"
          isOpen={modal_assignModal}
          toggle={() => {
            tog_assignModal();
          }}
          centered
        >
          <ModalHeader toggle={tog_assignModal}></ModalHeader>
          <ModalBody className="text-center">
            <Row>
              <Col sm={4}>
                {/* <div className="d-flex justify-content-center mt-4 mb-3"> */}
                <div className="placeholder-glow">
                  <span
                    className="placeholder col-12 img-thumbnail avatar-xxs "
                    style={{
                      height: "9rem",
                      width: "9rem",
                    }}
                  ></span>
                  {/* </div> */}
                </div>
              </Col>
              <Col sm={8}>
                <div className="confirmationText">
                  <h5 className="text-left">
                    <span className="placeholder col-8"></span>
                    <span className="placeholder col-6"></span>
                  </h5>
                  <p className="subText">
                    <span className="placeholder col-4"></span>
                  </p>
                  <div className="d-flex flex-wrap gap-2">
                    <Button className="w-lg cancelButton" color="primary">
                      <span className="placeholder col-6"></span>
                    </Button>
                    <Button className="w-lg confirmButton" color="primary">
                      <span className="placeholder col-6"></span>
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      ) : (
        <Modal
          className="zoomIn"
          id="addSeller"
          size="md"
          isOpen={modal_assignModal}
          toggle={tog_assignModal}
          centered
        >
          <ModalHeader toggle={tog_assignModal}></ModalHeader>
          <ModalBody className="text-center">
            <Row>
              <Col lg={4}>
                <img
                  src={atmCard}
                  alt=""
                  style={{ height: "9rem", width: "9rem" }}
                  className="  avatar-xxs "
                />
              </Col>

              <Col lg={8}>
                <div className="confirmationText">
                  <h5 className="text-left">
                    Please confirm that you want to{" "}
                    {isSwitchOn == false ? "enable" : "disable"} instant payout.
                  </h5>
                  <p className="subText">Confirm to proceed</p>
                  <div className="d-flex flex-wrap gap-2">
                    <Button
                      className="w-lg cancelButton"
                      color="primary"
                      // outline
                      onClick={tog_assignModal}
                      // className="btn btn-talent btn-sm mt-auto"
                    >
                      Cancel
                    </Button>
                    <Button
                      className="w-lg confirmButton"
                      color="primary"
                      // style={styles.confirmButton}
                      onClick={(event) => handleSwitchToggle(event)}
                    >
                      Confirm
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      )}
    </div>
  );
};
